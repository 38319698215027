import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Container from "@layout/Container/Container"

const Header = ({ siteTitle }) => (
  <header>
    <div className="header">
        <Container>
          <div className="header__logo">
              <Link
                to="/"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <span className="header__logo">
                  <img
                    width="60"
                    alt="Oblong Objects"
                    src="https://oblong-objects-media.s3-us-west-2.amazonaws.com/oblong-logo-160x90.png"
                  />
                </span>
            </Link>
          </div>
          <div className="header__title">
            <h1>
              <Link
                to="/"
              >
                { siteTitle }
              </Link>
            </h1>
          </div>
        </Container>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
